<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-top card-top-primary">
          <div class="card-header py-0">
            <vb-headers-card-header-tabbed :data="{ title: 'Transactions' }" />
          </div>
          <div class="card-body"><vb-tables-antd-2 /></div>
        </div>
      </div>
    </div>
    <div class="row"><div class="col-lg-12"></div></div>
  </div>
</template>

<script>
import VbHeadersCardHeaderTabbed from '@/@vb/widgets/Headers/CardHeaderTabbed'
import VbTablesAntd2 from '@/@vb/widgets/TablesAntd/2'

export default {
  name: 'VbTransactions',
  components: {
    VbHeadersCardHeaderTabbed,
    VbTablesAntd2,
  },
}
</script>
